exports.components = {
  "component---src-templates-404-404-jsx": () => import("./../../../src/templates/404/404.jsx" /* webpackChunkName: "component---src-templates-404-404-jsx" */),
  "component---src-templates-create-payment-create-payment-jsx": () => import("./../../../src/templates/CreatePayment/createPayment.jsx" /* webpackChunkName: "component---src-templates-create-payment-create-payment-jsx" */),
  "component---src-templates-e-books-pages-ebooks-jsx": () => import("./../../../src/templates/EBooksPages/Ebooks.jsx" /* webpackChunkName: "component---src-templates-e-books-pages-ebooks-jsx" */),
  "component---src-templates-feature-detail-feature-detail-jsx": () => import("./../../../src/templates/FeatureDetail/FeatureDetail.jsx" /* webpackChunkName: "component---src-templates-feature-detail-feature-detail-jsx" */),
  "component---src-templates-feature-overview-feature-overview-jsx": () => import("./../../../src/templates/FeatureOverview/FeatureOverview.jsx" /* webpackChunkName: "component---src-templates-feature-overview-feature-overview-jsx" */),
  "component---src-templates-form-pages-form-pages-jsx": () => import("./../../../src/templates/FormPages/FormPages.jsx" /* webpackChunkName: "component---src-templates-form-pages-form-pages-jsx" */),
  "component---src-templates-free-trial-page-free-trial-page-jsx": () => import("./../../../src/templates/FreeTrialPage/FreeTrialPage.jsx" /* webpackChunkName: "component---src-templates-free-trial-page-free-trial-page-jsx" */),
  "component---src-templates-landing-page-landing-page-jsx": () => import("./../../../src/templates/LandingPage/LandingPage.jsx" /* webpackChunkName: "component---src-templates-landing-page-landing-page-jsx" */),
  "component---src-templates-legal-pages-legal-pages-jsx": () => import("./../../../src/templates/LegalPages/LegalPages.jsx" /* webpackChunkName: "component---src-templates-legal-pages-legal-pages-jsx" */),
  "component---src-templates-new-home-page-new-home-page-jsx": () => import("./../../../src/templates/NewHomePage/NewHomePage.jsx" /* webpackChunkName: "component---src-templates-new-home-page-new-home-page-jsx" */),
  "component---src-templates-new-pricing-page-new-pricing-page-jsx": () => import("./../../../src/templates/NewPricingPage/NewPricingPage.jsx" /* webpackChunkName: "component---src-templates-new-pricing-page-new-pricing-page-jsx" */),
  "component---src-templates-overview-page-overview-page-jsx": () => import("./../../../src/templates/OverviewPage/OverviewPage.jsx" /* webpackChunkName: "component---src-templates-overview-page-overview-page-jsx" */),
  "component---src-templates-pricing-page-pricing-page-jsx": () => import("./../../../src/templates/PricingPage/PricingPage.jsx" /* webpackChunkName: "component---src-templates-pricing-page-pricing-page-jsx" */),
  "component---src-templates-references-page-reference-pages-jsx": () => import("./../../../src/templates/ReferencesPage/ReferencePages.jsx" /* webpackChunkName: "component---src-templates-references-page-reference-pages-jsx" */),
  "component---src-templates-search-page-search-page-jsx": () => import("./../../../src/templates/SearchPage/SearchPage.jsx" /* webpackChunkName: "component---src-templates-search-page-search-page-jsx" */),
  "component---src-templates-sector-page-sector-page-jsx": () => import("./../../../src/templates/SectorPage/SectorPage.jsx" /* webpackChunkName: "component---src-templates-sector-page-sector-page-jsx" */)
}

